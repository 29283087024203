// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: 'AIzaSyBdhKGL0iePWjmaZm9-l_jMbyRi4FSW_Ts',
//   authDomain: 'dst-sarathi-f1e4c.firebaseapp.com',
//   projectId: 'dst-sarathi-f1e4c',
//   storageBucket: 'dst-sarathi-f1e4c.appspot.com',
//   messagingSenderId: '245949331487',
//   appId: '1:245949331487:web:06688ac96d70c5c7a5bd2a',
// };

// const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth(firebaseApp);

// export { auth }; // Export the signInWithEmailAndPassword function


import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import { getFunctions } from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";
// import { getMessaging } from "firebase/messaging";
// import { getPerformance } from "firebase/performance";
// import { getRemoteConfig } from "firebase/remote-config";
// import { getDatabase } from "firebase/database";
// import { getFirestoreDoc, getFirestoreCollection } from "./firestore";
// import { getStorageFile, getStorageFiles } from "./storage";
// import { getFunctionsCallable } from "./functions";
// import { getAnalyticsLogEvent } from "./analytics";
// import { getMessagingToken } from "./messaging";
// import { getPerformanceTrace } from "./performance";
// import { getRemoteConfigValue } from "./remoteConfig";
// import { getDatabaseRef } from "./realtimeDatabase";


const firebaseConfig = {
  apiKey: "AIzaSyBdhKGL0iePWjmaZm9-l_jMbyRi4FSW_Ts",
  authDomain: "dst-sarathi-f1e4c.firebaseapp.com",
  projectId: "dst-sarathi-f1e4c",
  storageBucket: "dst-sarathi-f1e4c.appspot.com",
  messagingSenderId: "245949331487",
  appId: "1:245949331487:web:06688ac96d70c5c7a5bd2a",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
export { auth, app, firestore, storage };

//export { auth };
//export default app;