import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import './scss/style.scss';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// eslint-disable-next-line
import { app } from './firebase/firebase';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));

function App() {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true); // New loading state

  useEffect(() => {
    // Check if the user is already authenticated
    const auth = getAuth(); // Initialize Firebase auth
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      // Set loadingAuth to false once Firebase has checked the user's state
      setLoadingAuth(false);
    });

    // Unsubscribe from the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  // Render loading while Firebase is checking the user's state
  if (loadingAuth) {
    return loading;
  }

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="*"
            element={user ? <DefaultLayout /> : <Navigate to="/login" />}
          />
          <Route path="/login" name="Login Page" element={<Login />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
}

export default App;
